import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Rating } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { ApiConfig } from "./config/ApiConfig";
import Loader from "./Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function RatingPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialStar = queryParams?.get("star");
  const [org] = useState(queryParams?.get("org"));
  const [token] = useState(queryParams?.get("token"));
  const [feedbackDetails, setFeedbackDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // Define your API call here
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(ApiConfig.feedbackStatus, {
          access_token: token,
        });
        if (!response?.data?.is_feedback_submitted) {
          navigate("/feedbackexist");
        }
        setFeedbackDetails(response?.data?.fd_details);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, navigate]);

  // star Rating
  const [starRating, setStarRating] = useState(
    initialStar ? parseInt(initialStar, 10) : 0
  );
  const [timeRating, setqualityRating] = useState(0);
  const [happinessRating, sethappinessRating] = useState(0);
  const [agentKnowledgeRating, setagentKnowledgeRating] = useState(0);
  //agent Rating
  const up = `M20.22 9.55C19.79 9.04 19.17 8.75 18.5 8.75H14.47V6C14.47 4.48 13.24 3.25 11.64 3.25C10.94 3.25 10.31 3.67 10.03 4.32L7.49 10.25H5.62C4.31 10.25 3.25 11.31 3.25 12.62V18.39C3.25 19.69 4.32 20.75 5.62 20.75H17.18C18.27 20.75 19.2 19.97 19.39 18.89L20.71 11.39C20.82 10.73 20.64 10.06 20.21 9.55H20.22ZM5.62 19.25C5.14 19.25 4.75 18.86 4.75 18.39V12.62C4.75 12.14 5.14 11.75 5.62 11.75H7.23V19.25H5.62ZM17.92 18.63C17.86 18.99 17.55 19.25 17.18 19.25H8.74V11.15L11.41 4.9C11.45 4.81 11.54 4.74 11.73 4.74C12.42 4.74 12.97 5.3 12.97 5.99V10.24H18.5C18.73 10.24 18.93 10.33 19.07 10.5C19.21 10.67 19.27 10.89 19.23 11.12L17.91 18.62L17.92 18.63Z`;

  const down = `M18.38 3.25H6.81C5.72 3.25 4.79 4.03 4.6 5.11L3.29 12.61C3.18 13.27 3.36 13.94 3.78 14.45C4.21 14.96 4.83 15.25 5.5 15.25H9.53V18C9.53 19.52 10.76 20.75 12.36 20.75C13.06 20.75 13.69 20.33 13.97 19.68L16.51 13.75H18.39C19.7 13.75 20.76 12.69 20.76 11.38V5.61C20.76 4.31 19.7 3.25 18.39 3.25H18.38ZM15.26 12.85L12.59 19.1C12.55 19.19 12.46 19.26 12.27 19.26C11.58 19.26 11.03 18.7 11.03 18.01V13.76H5.5C5.27 13.76 5.07 13.67 4.93 13.5C4.78 13.33 4.73 13.11 4.77 12.88L6.08 5.38C6.14 5.02 6.45001 4.76 6.82 4.76H15.26V12.85ZM19.25 11.38C19.25 11.86 18.86 12.25 18.38 12.25H16.77V4.75H18.38C18.86 4.75 19.25 5.14 19.25 5.61V11.38Z`;

  // Source: https://www.svgrepo.com/svg/35304/up-arrow

  const ThumbsUp = <path d={up} />;
  const ThumbsDown = <path d={down} />;

  const customStyles = {
    itemShapes: [ThumbsUp, ThumbsDown],
    activeFillColor: ["#16A34A", "#E11D48"],
    activeBoxBorderColor: ["#16A34A", "#E11D48"],
    inactiveFillColor: "#9CA3AF",
    inactiveBoxBorderColor: "#9CA3AF",
  };

  const [thumbsRating, setThumbsRating] = useState(0);
  const [thumbsRatingBol, setThumbsRatingBol] = useState(0);
  const handleThumbsRatingChange = (numericRating) => {
    setThumbsRating(numericRating);
    const booleanRating = numericRating === 1 ? true : false;
    setThumbsRatingBol(booleanRating);
  };

  // comments and submit Feedback

  const maxLength = 1000;
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
  const [comments, setComments] = useState("");
  const [charsLeft, setCharsLeft] = useState(maxLength);

  const handleCommentChange = (event) => {
    const newComments = event.target.value;
    setComments(newComments);
    setCharsLeft(maxLength - newComments.length);
  };

  const handleFeedback = async () => {
    if (starRating <= 0) {
      toast.error("Rating cannot be empty.");
      return;
    }
    setIsLoadingFeedback(true);
    try {
      await axios.post(ApiConfig.sendFeedback, {
        rating: starRating,
        time_rating: timeRating,
        happiness_rating: happinessRating,
        agent_knowledge_rating: agentKnowledgeRating,
        thumbs_up: thumbsRatingBol,
        comments: comments,
        access_token: token,
      });
      navigate("/thankyou");
    } catch (error) {
    } finally {
      setIsLoadingFeedback(false);
    }
  };

  return (
    // <div className="feedback-container" style={{ height: "100vh" }}>
    <div
      className="container p-3 card my-4 feedback-container"
      style={{ maxWidth: "50rem" }}
    >
      <ToastContainer />
      {/* <div
        className="mb-3"
        style={{
          border: "none",
          height: "8px",
          backgroundColor: "#888",
        }}
      ></div> */}
      <div
        className="my-3"
        style={{
          border: "none",
          height: "8px",
          backgroundColor: "#888",
        }}
      ></div>
      {/* <div className="p-3 "> */}
      <h3 className="text-center mb-4 fs-4 fw-semibold text-secondary">
        {/* {org?.toLocaleUpperCase()} */}
        {`${org?.charAt(0)?.toUpperCase()}${org?.slice(1)}`}
      </h3>
      {isLoading && <Loader />}
      {feedbackDetails === "10-Star_csat" && (
        <section className="mt-2">
          <div className="card-bg rounded-4 p-2 feedback-section">
            <p className="text-center fs-5 mb-1">
              How would you rate the overall quality of your recent support
              experience?
            </p>
            <div className="d-flex justify-content-center">
              <Rating
                style={{
                  maxWidth: "25rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                items={10}
                value={starRating}
                onChange={setStarRating}
              />
            </div>
          </div>
        </section>
      )}
      {feedbackDetails === "5-Star_csat" && (
        <section className="mt-2">
          <div className="card-bg rounded-4 p-2 feedback-section">
            <p className="text-center fs-5 mb-1">
              How would you rate the overall quality of your recent support
              experience?
            </p>
            <div className="d-flex justify-content-center">
              <Rating
                style={{
                  maxWidth: "12rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                value={starRating}
                onChange={setStarRating}
              />
            </div>
          </div>
        </section>
      )}
      {feedbackDetails === "10-Star_nps" && (
        <>
          <section className="mt-2">
            <div className="card-bg rounded-4 p-2 feedback-section">
              <p className="text-center fs-5 mb-1">
                How would you rate the overall quality of your recent support
                experience?
              </p>
              <div className="d-flex justify-content-center">
                <Rating
                  style={{
                    maxWidth: "25rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  items={10}
                  value={starRating}
                  onChange={setStarRating}
                />
              </div>
            </div>
          </section>
          <section className="mt-2">
            <div className="card-bg rounded-4 p-2 feedback-section">
              <p className="text-center fs-5 mb-1">
                How happy are you with the time taken to resolve the issue?
              </p>
              <div className="d-flex justify-content-center">
                <Rating
                  style={{
                    maxWidth: "25rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  items={10}
                  value={timeRating}
                  onChange={setqualityRating}
                />
              </div>
            </div>
          </section>
          <section className="mt-2">
            <div className="card-bg rounded-4 p-2 feedback-section">
              <p className="text-center fs-5 mb-1">
                Are you satisfied with the knowledge of the agent related to
                this issue?
              </p>
              <div className="d-flex justify-content-center">
                <Rating
                  style={{
                    maxWidth: "25rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  items={10}
                  value={happinessRating}
                  onChange={sethappinessRating}
                />
              </div>
            </div>
          </section>
          <section className="mt-2">
            <div className="card-bg rounded-4 p-2 feedback-section">
              <p className="text-center fs-5 mb-1">
                How happy are you with the behavior of the agent?
              </p>
              <div className="d-flex justify-content-center">
                <Rating
                  style={{
                    maxWidth: "25rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  items={10}
                  value={agentKnowledgeRating}
                  onChange={setagentKnowledgeRating}
                />
              </div>
            </div>
          </section>
        </>
      )}
      <section className="mt-2">
        <div className="card-bg rounded-4 p-2 feedback-section">
          <p className="text-center fs-5 mb-1">
            Did we resolve this issue to your satisfaction?
          </p>
          <div className="d-flex justify-content-center">
            <Rating
              style={{ maxWidth: "15rem" }}
              value={thumbsRating}
              onChange={(value) => handleThumbsRatingChange(value)}
              itemStyles={customStyles}
              items={2}
              highlightOnlySelected
              orientation="horizontal"
              spaceBetween="medium"
              spaceInside="large"
              invisibleLabel="Upvote or downvote this post"
              invisibleItemLabels={["Upvote", "Downvote"]}
            />
          </div>
        </div>
      </section>
      <section className="mt-2">
        <div className="card-bg rounded-4 p-2 feedback-section">
          <p className="text-center fs-5 mb-1">
            Please explain why you gave this score.
          </p>
          <div className="d-flex justify-content-center mb-2">
            <div style={{ width: "30rem" }}>
              <textarea
                className="form-control mt-2"
                placeholder="Leave a comment here..."
                style={{
                  height: "100px",
                  width: "100%",
                }}
                value={comments}
                onChange={handleCommentChange}
                maxLength={maxLength}
              ></textarea>
              <p className="text-muted ms-2" style={{ fontSize: "10px" }}>
                {charsLeft} character(s) left
              </p>
            </div>
          </div>
        </div>
      </section>
      <div
        className="btn-group mx-auto d-flex justify-content-center w-25 mt-3"
        role="group"
        aria-label="Basic mixed styles example"
      >
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleFeedback}
          disabled={isLoadingFeedback}
        >
          {isLoadingFeedback ? "submitting..." : "submit"}
        </button>
      </div>
      <p className="text-center" style={{ fontSize: "14px", color: "#ccc" }}>
        Powered by{" "}
        <a
          href="https://www.topcx.ai"
          target="_blank"
          rel="noopener noreferrer"
          className="fw-semibold"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          TopCX
        </a>
      </p>
    </div>
    // </div>
  );
}
